<template>
  <div v-show="ad" class="advert">
    <div class="advert__flex">
      <div class="advert__left">
        <img class="advert__logo" :src="require(`@/assets/${logo}`)" />
      </div>
      <div class="advert__right">
        <h4 class="advert__title">{{ title }}</h4>
      </div>
    </div>
    <div class="advert__terms">
      <p class="advert__text" v-html="terms"></p>
    </div>
    <div class="advert__btns">
      <a
        :href="link"
        target="_blank"
        rel="noopener noreferrer nofollow"
        class="btn btn--primary"
        :style="{ background: background }"
        >Take me there now!</a
      >
      <button @click="ad = !ad" class="btn btn--secondary">Close</button>
    </div>
    <div class="advert__terms">
      <p class="advert__text" v-html="additional_terms"></p>
    </div>
    <div class="advert__sponsored">
      <p class="advert__sponsored-text">Sponsored Ad</p>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";

export default {
  props: {
    logo: String,
    title: String,
    terms: String,
    additional_terms: String,
    link: String,
    background: String,
  },
  setup() {
    const ad = ref(true);

    // Returning reactive properties and methods
    return {
      ad,
    };
  },
};
</script>

<style lang="scss" scoped>
.advert {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -20%);
  background: white;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 1em;
  width: 80%;
  max-width: 600px;
  @media only screen and (min-width: 700px) {
    width: 100%;
    padding: 1.5em;
  }
  &__flex {
    @media only screen and (min-width: 700px) {
      display: flex;
    }
  }
  &__left {
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (min-width: 700px) {
      width: 45%;
    }
  }
  &__right {
    display: flex;
    justify-content: center;
    text-align: center;
    @media only screen and (min-width: 700px) {
      justify-content: start;
      text-align: left;
      width: 55%;
    }
  }
  &__logo {
    height: 30px;
  }
  &__title {
    margin: 0;
  }
  &__terms {
    text-align: center;
    font-size: 0.8em;
  }
  &__text {
    color: #8f88a0;
  }
  &__btns {
    display: flex;
    justify-content: center;
    & > .btn {
      margin: 0 2px;
    }
  }
  &__sponsored-text {
    font-size: 0.8em;
    color: rgb(109, 104, 121);
    text-align: center;
  }
}
</style>
