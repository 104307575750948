<template>
  <header class="header" :style="{ background: background }">
    <img
      v-show="site == 'www.supafinance.co.uk'"
      :alt="site + ' logo'"
      class="header__logo"
      src="https://www.supafinance.co.uk/img/logo-new.png"
    />
    <img
      v-show="site == 'decemberdosh.co.uk'"
      :alt="site + ' logo'"
      class="header__logo"
      src="https://www.decemberdosh.co.uk/images/logo.png"
    />
    <img
      v-show="site == 'justjingle.co.uk'"
      :alt="site + ' logo'"
      class="header__logo"
      src="https://www.justjingle.co.uk/images/logo.png"
    />
    <img
      v-show="site == 'www.supaloans.co.uk'"
      :alt="site + ' logo'"
      class="header__logo"
      src="https://www.supaloans.co.uk/logo-new.png"
    />
    <img
      v-show="site == 'www.tangerineloans.com'"
      :alt="site + ' logo'"
      class="header__logo"
      src="https://tangerineloans.com/img/tangerine-logo-new.png"
    />
    <img
      v-show="site == 'www.theloantree.co.uk'"
      :alt="site + ' logo'"
      class="header__logo"
      src="https://www.theloantree.co.uk/images/logo-new.png"
    />
  </header>
  <section class="content">
    <div class="processing" v-if="!crPage">
      <!-- <div v-if="site === 'www.supafinance.co.uk' || site === 'www.supaloans.co.uk' " ref="lottieContainer" class="processing__lottie"></div> -->

      <div class="loading-container">
        <div :style="{ background: backgroundAlt }" class="ball ball1"></div>
        <div :style="{ background: backgroundAlt }" class="ball ball2"></div>
        <div :style="{ background: backgroundAlt }" class="ball ball3"></div>
      </div>

      <h1 class="processing__h1">Finding you a lender...</h1>
      <p class="processing__time">
        This may take up to two minutes, please do not refresh the page
      </p>
      <div class="processing__bar">
        <div
          class="processing__bar processing__bar--line"
          :style="{
            width: (loansReceived * 100) / totalLoans + '%',
            background: backgroundAlt,
          }"
        ></div>
      </div>
      <h5 class="processing__h5">
        So far we have checked
        <span class="processing__loans-received"
          >{{ loansReceived }} out of {{ totalLoans }}</span
        >
        loans for you
      </h5>
    </div>
    <div class="processing" v-else>
      <h1 class="processing__h1 processing__h1--cr">
        Finding your credit report...
      </h1>
      <div class="processing__bar">
        <div
          class="processing__bar processing__bar--line"
          :style="{
            width: (loansReceived * 100) / totalLoans + '%',
            background: backgroundAlt,
          }"
        ></div>
      </div>
    </div>
  </section>
  <section class="ad" :style="{ background: background }">
    <advert-component
      v-if="
        site === 'www.supaloans.co.uk' ||
        site === 'www.tangerineloans.com' ||
        site === 'decemberdosh.co.uk' ||
        site === 'justjingle.co.uk'
      "
      logo="yourloantoday.png"
      title="Loans from £250 to £3,000 - Money the same day* - No Hidden Costs"
      :link="`https://yourloantoday.co.uk/?sid=${tracking}`"
      terms="<b>Representative example:</b> If you borrow £550 over 18 months at a flat rate of 168% per annum (fixed) with a representative 679% APR you will make 18 monthly payments of £107.56, repaying £1,936.08 in total. The rate and / or term you are offered is dependent on your individual circumstances."
      :background="backgroundAlt"
    ></advert-component>
    <advert-component
      v-else
      logo="quidmarket.jpg"
      title="Loans from £300 to £1500* - No Hidden Costs"
      :link="`https://www.quidmarketloans.com/?utm_source=MB&utm_medium=referral&utm_campaign=${tracking}`"
      terms="<b>Representative example:</b> Borrow £300 for 3 months - Interest payable £154.38 - Total amount payable: £454.38 in 3 instalments - 3 payments of £151.46 - Representative 1,298.25%. APR - Interest rate 292% per annum (fixed)."
      additional_terms="Repayment periods are 3 months to 6 months, Additional options may be available to you as a repeat customer. Total Maximum APR 1,623.90%"
      :background="backgroundAlt"
    ></advert-component>
    <div class="terms">
      <div v-html="cmsRepAprData" />
      <div v-html="cmsTermsData" />
      <div v-html="cmsRatesData" />
    </div>
    <ul class="footer-links">
      <li class="footer-links__item">
        <a
          class="footer-links__link"
          target="_blank"
          :href="`https://${site}/terms-and-conditions`"
          >Terms &amp; Conditions</a
        >
      </li>
      <li class="footer-links__item">
        <a
          class="footer-links__link"
          target="_blank"
          :href="`https://${site}/treating-customers-fairly`"
          >Treating Customers Fairly</a
        >
      </li>
      <li class="footer-links__item">
        <a
          class="footer-links__link"
          target="_blank"
          :href="`https://${site}/privacy-policy`"
          >Privacy Policy</a
        >
      </li>
      <li class="footer-links__item">
        <a
          class="footer-links__link"
          target="_blank"
          :href="`https://${site}/responsible-lending`"
          >Responsible Lending</a
        >
      </li>
      <li class="footer-links__item">
        <a
          class="footer-links__link"
          target="_blank"
          :href="`https://${site}/customer-complaints`"
          >Customer Complaints Procedure</a
        >
      </li>
    </ul>
    <div class="date">
      <p>Copyright MediaBlanket <span v-html="dateYear" /></p>
    </div>
  </section>
</template>
<script>
import { ref, onMounted, computed } from "vue";
import axios from "axios";
import lottie from "lottie-web";
import animationData from "@/assets/loading.json";
import AdvertComponent from "./AdvertComponent";

export default {
  components: {
    AdvertComponent,
  },
  setup() {
    // lottie is the animation
    const lottieContainer = ref(null);

    const crPage = ref(false);
    const count = ref(0);

    let loansReceived = ref("...");
    let totalLoans = ref("...");
    const cmsTermsData = ref("Getting terms");
    const cmsRatesData = ref("Getting rates");
    const cmsRepAprData = ref("Getting apr");
    const dateYear = ref("");
    const leadId = ref("");
    const site = ref("www.supafinance.co.uk");
    const ad = ref(true);
    const background = ref("#0d122b");
    const backgroundAlt = ref("#F02EA9");

    onMounted(() => {
      setInterval(function () {
        count.value++;

        if (count.value > 480)
          window.location.href =
            "https://tangerineloans.com/options-tangerine/?utm_source=supaloansv2&utm_campaign=supaloansv2exit&utm_medium=exit&redirect_reason=supaloansv2_processing_timeout";
      }, 1000);

      getDate();
      getCmsContent();
      getParams();
      initLottie();
      setBackgroundColor();
      if (leadId.value) checkLeads();
    });

    const tracking = computed(() => {
      if (site.value == "www.supafinance.co.uk") return "SF";
      else if (site.value == "decemberdosh.co.uk") return "293";
      else if (site.value == "justjingle.co.uk") return "294";
      else if (site.value == "www.supaloans.co.uk") return "110";
      else if (site.value == "www.tangerineloans.com") return "111";
      else if (site.value == "www.theloantree.co.uk") return "TLT";
      else return "SF";
    });

    const getDate = () => {
      const currentDate = new Date();
      dateYear.value = currentDate.getFullYear();
    };

    const getCmsContent = () => {
      const endpoint = `https://leads.lynniar.com/api/content-editor/site/18`;

      axios.get(endpoint).then((response) => {
        cmsTermsData.value = response.data.pages[0].content_3;
      });

      axios.get(endpoint).then((response) => {
        cmsRatesData.value = response.data.pages[1].content_2;
      });

      axios.get(endpoint).then((response) => {
        cmsRepAprData.value = response.data.pages[0].content_2;
      });
    };

    const initLottie = () => {
      // Initialize Lottie animation
      lottie.loadAnimation({
        container: lottieContainer.value,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: animationData,
      });
    };

    const getParams = () => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const isCrPage = urlParams.has("cr");

      if (urlParams.has("id")) leadId.value = urlParams.get("id");
      if (urlParams.has("site")) site.value = urlParams.get("site");

      if (isCrPage) crPage.value = true;
    };

    const setBackgroundColor = () => {
      if (site.value === "www.supafinance.co.uk") {
        background.value = "#0d122b";
        backgroundAlt.value = "#F02EA9";
      } else if (site.value === "decemberdosh.co.uk") {
        background.value = "#6C71F3";
        backgroundAlt.value = "#F18F02";
      } else if (site.value === "justjingle.co.uk") {
        background.value = "#D83658";
        backgroundAlt.value = "#4778FF";
      } else if (site.value === "www.supaloans.co.uk") {
        background.value = "#0F3943";
        backgroundAlt.value = "#4CAF50";
      } else if (site.value === "www.tangerineloans.com") {
        background.value = "#100E0A";
        backgroundAlt.value = "#F0AE00";
      } else if (site.value === "www.theloantree.co.uk") {
        background.value = "#142031";
        backgroundAlt.value = "#48BB78";
      }
    };

    const checkLeads = () => {
      let endpoint = null;

      // when running npm run serve NODE_ENV will return development
      // npm run build will return production

      const env = process.env.NODE_ENV;

      // this public key is not secret it is just an addedd layer of security
      const public_key = process.env.VUE_APP_PUBLIC_KEY;

      // the endpoint for dev and live is added in amplify env variables within aws console
      // you may also need to edit the amplify.yml file if you add a new env variable

      if (env === "development")
        endpoint = "http://localhost:3000/lead-response";
      else endpoint = process.env.VUE_APP_BACKEND_ENDPOINT;

      axios
        .get(`${endpoint}?public_key=${public_key}&lead_id=${leadId.value}`)
        .then((response) => {
          let data = response.data;

          loansReceived.value = data.sentTo;
          totalLoans.value = data.totalTree;

          if (data.redirectUrl !== "") {
            window.location.href = data.redirectUrl;
          } else {
            setTimeout(() => {
              checkLeads();
            }, 6500);
          }
        })
        .catch((error) => {
          console.log(error);
          setTimeout(() => {
            checkLeads();
          }, 6500);
        });
    };
    // Returning reactive properties and methods
    return {
      loansReceived,
      totalLoans,
      crPage,
      checkLeads,
      lottieContainer,
      cmsTermsData,
      cmsRatesData,
      cmsRepAprData,
      dateYear,
      site,
      ad,
      background,
      backgroundAlt,
      count,
      tracking,
    };
  },
};
</script>

<style lang="scss" scoped>
.header {
  background: $dark-color;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  &__logo {
    width: 200px;
    height: auto;
    margin: 0;
  }
}

.processing {
  margin: 0 0 120px;
  padding: 0 10px;
  &__lottie {
    display: block;
    margin: 0 auto;
    width: 200px;
    height: 200px;
    @media only screen and (min-width: 700px) {
      width: 300px;
      height: 300px;
    }
  }
  &__h1 {
    margin: 0;
    padding: 0;
    &--cr {
      margin: 50px 0 20px;
    }
  }
  &__h5 {
    margin: 0;
    padding: 10px 0 0;
    color: #8f88a0;
  }
  &__time {
    color: #8f88a0;
  }
  &__bar {
    background: rgb(209, 209, 209);
    height: 5px;
    border-radius: 5px;
    width: 90%;
    display: block;
    margin: 0 auto;
    position: relative;
    &--line {
      width: 1%;
      background: $primary-color;
      z-index: 2;
      position: absolute;
    }
  }
}

.ad {
  position: relative;
  height: 120%;
  min-height: 660px;
}

.terms {
  & > p {
    color: white !important;
  }
  width: 90%;
  max-width: 1400px;
  display: block;
  margin: 0 auto;
  text-align: center;
  font-size: 0.8em;
  padding-top: 420px;

  @media only screen and (min-width: 360px) {
    padding-top: 380px;
  }

  @media only screen and (min-width: 600px) {
    padding-top: 300px;
  }
}

.terms > p {
  color: white !important;
}

.date {
  & > p {
    text-align: center;
    padding: 0;
    margin: 0;
  }
}

.footer-links {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  list-style-type: none;
  color: white;
  &__item {
    margin: 50px 30px 20px 0;
  }
  &__link {
    color: white;
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  margin: 40px 0 10px;
}

.ball {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  margin: 0 10px;
  animation: bounce 0.75s infinite alternate;
}

.ball1 {
  animation-delay: 0.2s;
}

.ball2 {
  animation-delay: 0.4s;
}

.ball3 {
  animation-delay: 0.6s;
}

@keyframes bounce {
  to {
    transform: translateY(-30px);
  }
}
</style>
